
export default function OurClients(){

    return (
      <div className="flex flex-col items-center justify-center">

        
        <div className="relative">
          <p className="font-Secondary  md:text-[3rem] font-bold">
            Proven Client Satisfaction
          </p>
        </div>
        <div className="flex flex-row flex-wrap items-center justify-center">
          <img
            src="Assets/Images/testClients.png"
            className="md:w-[340px]"
            alt=""
          />
          <img
            src="Assets/Images/testClients.png"
            className="md:w-[340px]"
            alt=""
          />
          <img
            src="Assets/Images/testClients.png"
            className="md:w-[340px]"
            alt=""
          />
          <img
            src="Assets/Images/testClients.png"
            className="md:w-[340px]"
            alt=""
          />
          <img
            src="Assets/Images/testClients.png"
            className="md:w-[340px]"
            alt=""
          />
        </div>
      </div>
    );
}